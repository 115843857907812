import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import requests from "../../services/requests";

import Card from "../../components/Cards";
import GraphMap from "./../../components/Graphs/GraphMap";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import TitlePurple from "../../components/Typography/TitlePurple";
import Metric from "./components/Metric";
import ProcessesMarkedAsPriority from "./components/ProcessesMarkedAsPriority";
import Submenu from "../../components/Menus/Submenu";
import Updates from "./components/Updates";
import WeeklySchedule from "./components/WeeklySchedule";

const DashboardPage = () => {
    const filterInformation = useSelector(state => state.filter.value);
    const searchPeriod = useSelector(state => state.period);
    const [searchString, setSearchString] = useState("");
    const [cancelTokenSource, setCancelTokenSource] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onChangeSearchString = value => {
        let search = value.trim();
        if (search.length === 0 || search.length > 2) {
            setSearchString(search);
        }
    };

    const countProcess = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel("cancel");
        }

        var currentCancelToken = axios.CancelToken.source();
        setCancelTokenSource(currentCancelToken);
        if (searchString.length > 2) {
            setIsLoading(true);
            requests
                .countProcess(
                    {
                        search: searchString,
                    },
                    currentCancelToken
                )
                .then(data => {
                    setIsLoading(false);
                    if (data.qtt === 1) {
                        window.location.href = "/processos/detalhes/" + data.identifier;
                    } else {
                        window.location.href = "/processos/";
                    }
                    setCancelTokenSource(null);
                });
        }
    };

    useEffect(() => {
        countProcess();
    }, [searchString]);

    return (
        <Layout>
            <SEO title="Home" />
            <Submenu
                onChangeSearchString={onChangeSearchString}
                type={{ filters: true, shortcut: true }}
                isLoading={isLoading}
            />
            <div className="p-4">
                <div className="flex flex-wrap">
                    <Card className="md:w-7/12 flex-grow">
                        <TitlePurple className="mb-5">MAPA DE IMPORTAÇÕES</TitlePurple>
                        {/*<RealTimeMap />*/}
                        <GraphMap filterInformation={filterInformation} searchPeriod={searchPeriod} />
                    </Card>
                    <Updates filterInformation={filterInformation} />
                </div>
                <Card className="mt-4">
                     <Card className="mt-4">
                        <TitlePurple>agenda da semana</TitlePurple>
                        <div className="border-t mt-4 pt-4 border-C2 border-solid overflow-auto">
                            <WeeklySchedule filterInformation={filterInformation} />
                        </div>
                    </Card>
                    <Card className="mt-4">
                        <TitlePurple>processos marcados como prioridade</TitlePurple>
                        <div className="border-t mt-4 pt-4 border-C2 border-solid">
                            <ProcessesMarkedAsPriority />
                        </div>
                    </Card>
                    <TitlePurple>métricas</TitlePurple>
                    <div className="border-t mt-4 pt-4 border-C2 border-solid">
                        <Metric filterInformation={filterInformation} searchPeriod={searchPeriod} />
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default DashboardPage;
