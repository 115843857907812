import React, { useState, useEffect } from "react";
import requests from "../../../services/requests";
import ClipLoader from "react-spinners/ClipLoader";
import TrackProcess from "../../../components/TrackProcess";
import { Link } from "gatsby";

const ProcessesMarkedAsPriority = () => {
    const [load, setLoad] = useState(false);
    const [list, setList] = useState([]);

    const listProcess = () => {
        setLoad(true);
        requests.listProcessPriority().then(data => {
            window.localStorage.setItem("profile-process-priority", JSON.stringify(data.map(process => process.identifier)));
            setList(data);
            setLoad(false);
        });
    };

    useEffect(() => {
        listProcess();
    }, []);

    return (
        <>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <>
                    {list && list.map(process => <TrackProcess key={process.identifier} priorities process={process} />)}
                    {list.length === 0 && (
                        <div className="flex flex-col items-center">
                            Sem processos prioritários
                            <small>
                                Utilize o ícone ao lado do código do processo para marcá-lo como favorito e vê-lo aqui.
                            </small>
                        </div>
                    )}
                    <div className="flex justify-end">
                        <Link to={`/processos`}>
                            <p className="text-roxo_oficial cursor-pointer">ver todos os processos</p>
                        </Link>
                    </div>
                </>
            )}
        </>
    );
};
export default ProcessesMarkedAsPriority;
